import React from "react";
import "./css/contactus.style.css";

const ContactUs = () => {
  // Static contact history data
  const contactHistory = [
    {
      name: "John Doe",
      email: "john@example.com",
      message: "I need help with my account.",
      date: "2024-12-01",
    },
    {
      name: "Jane Smith",
      email: "jane@example.com",
      message: "Can you provide more information about your services?",
      date: "2024-11-25",
    },
  ];

  return (
    <div className="contactus-container">
      <h2>Contact Us</h2>
      
      {/* Contact Form */}
      <div className="contactus-form">
        <h3>Send us a message</h3>
        <form>
          <div className="contactus-form-field">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          
          <div className="contactus-form-field">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          
          <div className="contactus-form-field">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required></textarea>
          </div>
          
          <button type="submit">Submit</button>
        </form>
      </div>

      {/* Contact History */}
      <div className="contactus-history">
        <h3>Previous Contacts</h3>
        <ul>
          {contactHistory.map((contact, index) => (
            <li key={index} className="contactus-history-item">
              <div className="contactus-contact-info">
                <strong>{contact.name}</strong>
                <span>{contact.email}</span>
                <span>{contact.date}</span>
              </div>
              <p>{contact.message}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ContactUs;
