import React from 'react';
import './css/premiumnotificationmodal.style.css';

const PremiumNotificationModal = ({ isOpen, onClose, onGetPremium }) => {
  if (!isOpen) return null;

  return (
    <div className="premiumnotificationmodal-overlay">
      <div className="premiumnotificationmodal-content">
        <h2 className="premiumnotificationmodal-title">Get Premium to Unlock Unlimited Friends</h2>
        <p className="premiumnotificationmodal-description">
          Upgrade to Premium to be able to add more than 3 friends.
        </p>
        <div className="premiumnotificationmodal-actions">
          <button className="premiumnotificationmodal-btn-premium" onClick={onGetPremium}>
            Get Premium
          </button>
          <button className="premiumnotificationmodal-btn-close" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PremiumNotificationModal;
