import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Firebase config import
import { collection, getDocs, doc, getDoc, updateDoc, deleteDoc, setDoc } from 'firebase/firestore'; // Import necessary Firestore functions
import Footer from '../Footer';
import fallbackImage from './ano.jpg';
import './css/notification.style.css'; // Import the CSS file

function NotificationPage() {
  const [friendRequests, setFriendRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFriendRequests = async () => {
      try {
        const uid = localStorage.getItem('uid'); // Get logged-in user UID from localStorage

        if (!uid) {
          setError('User not logged in');
          setLoading(false);
          return;
        }

        // Access the 'friendRequest' sub-collection of the logged-in user
        const friendRequestsRef = collection(db, 'users', uid, 'friendRequest');
        const snapshot = await getDocs(friendRequestsRef);

        if (snapshot.empty) {
          console.log('No friend requests found.');
        } else {
          const requests = [];

          // For each friend request, fetch user details (username, photoURL, etc.)
          for (let docSnap of snapshot.docs) {
            const friendUid = docSnap.id; // Assuming the document ID is the UID of the sender
            const userRef = doc(db, 'users', friendUid);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
              const userData = userSnap.data();
              requests.push({
                uid: friendUid,
                name: userData.name,
                username: userData.username,
                photoURL: userData.photoURL || fallbackImage, // Use a fallback if photoURL is not present
              });
            }
          }

          console.log('Fetched friend requests:', requests);
          setFriendRequests(requests); // Store the friend requests in the state
        }
      } catch (err) {
        setError('Failed to load friend requests');
        console.error('Error fetching friend requests:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchFriendRequests(); // Run the function to fetch friend requests when the component mounts
  }, []); // Empty dependency array means this runs once after the component mounts
  const handleAccept = async (friendUid) => {
    try {
      const uid = localStorage.getItem('uid'); // Get logged-in user's UID
      if (!uid) {
        setError('User not logged in');
        return;
      }
  
      // Add the sender to the recipient's friend list
      const userFriendsRef = doc(db, 'users', uid, 'friends', friendUid);
      await setDoc(userFriendsRef, { uid: friendUid });
  
      // Add the recipient to the sender's friend list
      const friendFriendsRef = doc(db, 'users', friendUid, 'friends', uid);
      await setDoc(friendFriendsRef, { uid: uid });
  
      // Remove the friend request from the recipient's side
      const requestRef = doc(db, 'users', uid, 'friendRequest', friendUid);
      await deleteDoc(requestRef);
  
      // (Optional) Remove the friend request from the sender's `sentRequests` sub-collection
      const sentRequestRef = doc(db, 'users', friendUid, 'sentRequest', uid);
      await deleteDoc(sentRequestRef);
  
      // Update state to reflect the change
      setFriendRequests((prev) => prev.filter((request) => request.uid !== friendUid));
    } catch (err) {
      console.error('Error accepting friend request:', err);
      setError('Failed to accept friend request');
    }
  };
  
  const handleDecline = async (friendUid) => {
    try {
      const uid = localStorage.getItem('uid'); // Get logged-in user's UID
      if (!uid) {
        setError('User not logged in');
        return;
      }
  
      // Remove the friend request
      const requestRef = doc(db, 'users', uid, 'friendRequest', friendUid);
      await deleteDoc(requestRef);

      // (Optional) Remove the friend request from the sender's `sentRequests` sub-collection
      const sentRequestRef = doc(db, 'users', friendUid, 'sentRequest', uid);
      await deleteDoc(sentRequestRef);
  
      // Update state to reflect the change
      setFriendRequests((prev) => prev.filter((request) => request.uid !== friendUid));
    } catch (err) {
      console.error('Error declining friend request:', err);
      setError('Failed to decline friend request');
    }
  };
  
  

  return (
<div className="notification-container">
  {loading && <p>Loading...</p>}
  {error && <p>{error}</p>}

  {/* Display the friend requests as banners */}
  <div className="notification-list">
    {friendRequests.map((request) => (
      <div key={request.uid} className="notification-item">
        <div className="notification-header">
          <img
            src={request.photoURL}
            alt={`${request.username}'s profile`}
            className="notification-avatar"
          />
          <div className="notification-details">
            <h4 className="notification-username">{request.username}</h4>
            <p className="notification-name">{request.name}</p>
          </div>
        </div>

        {/* Buttons aligned in one line */}
        <div className="notification-buttons">
          <button
            className="notification-button accept"
            onClick={() => handleAccept(request.uid)}
          >
            Accept
          </button>
          <button
            className="notification-button reject"
            onClick={() => handleDecline(request.uid)}
          >
            Reject
          </button>
        </div>
      </div>
    ))}
  </div>

  <Footer />
</div>

  );
}

export default NotificationPage;
