import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import { getFirestore, doc, getDoc } from "firebase/firestore"; 
import { db } from '../../firebase'; // Import the Firestore instance from your firebase config

import './css/profile.style.css'; 

function ProfilePage() {
  const navigate = useNavigate();
  const [premium, setPremium] = useState(false); // State to store the premium status
  const uid = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')).uid
    : null; // Retrieve uid from localStorage

  // Fetch the user's premium status from Firestore using the uid
  useEffect(() => {
    const fetchPremiumStatus = async () => {
      if (uid) {
        try {
          // Reference the user's document in Firestore using their uid
          const userDocRef = doc(db, "users", uid); // Assuming "users" is your collection
          const userSnap = await getDoc(userDocRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            setPremium(userData.premium || false); // Set premium status (default to false if not available)
          } else {
            console.log("No such user document!");
            setPremium(false); // Default to false if no document found
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          setPremium(false); // Default to false on error
        }
      }
    };

    fetchPremiumStatus(); // Fetch premium status when the component mounts
  }, [uid]);

  const handleLogout = () => {
    // Clear session or authentication data here
    localStorage.removeItem('accessToken'); // Remove token from localStorage
    localStorage.removeItem('user'); // Clear user data from localStorage
    navigate('/'); // Navigate to the home page ("/")
  };

  const handleDeleteAccount = () => {
    // Implement account deletion logic here
    alert('Account Deleted'); // Replace with actual deletion logic
  };

  if (uid === null) {
    return <p>Please log in to view your profile.</p>; // Show if no uid (user not logged in)
  }

  return (
    <div className="opt-profile-page">
      <div className="opt-profile-content">
        {/* Premium Account Section */}
        {!premium && (
  <div className="opt-premium-status">
    <h3>Account Status</h3>
    <h5>You do not have a Premium account.</h5>
    <a href="https://subscribe.ringmatch.life" target="_blank" rel="noopener noreferrer">
  <button style={{ marginTop: '10px' }}>
    Buy Premium
  </button>
</a>

  </div>
)}

        <div className="opt-profile-options">
          <h3>Profile</h3>
          <ul>
            <li onClick={() => navigate('/yourprofile')}>View Profile</li>
            <li onClick={() => navigate('/editprofile')}>Edit Profile</li>
            <li onClick={() => navigate('/friends')}>Friends</li>
          </ul>

          <h3>Account Settings</h3>
          <ul>
            <li onClick={() => navigate('/account/settings')}>Account Settings</li>
            <li onClick={() => navigate('/account/privacy')}>Account Privacy</li>
            <li onClick={() => navigate('/billing')}>Billing</li>
            <li onClick={() => navigate('/blocked')}>Blocked Users</li>
          </ul>

          <h3>Help & Info</h3>
          <ul>
            <li onClick={() => navigate('/developer-team')}>Developer Team</li>
            <li onClick={() => navigate('/contactus')}>Contact Us</li>
            <li onClick={() => navigate('/privacypolicy')}>Privacy Policy</li>
            <li onClick={() => navigate('/termsandconditions')}>Terms & Conditions</li>
          </ul>

          <h3>Actions</h3>
          <ul>
            <li onClick={handleLogout} style={{ color: '#FF7FB6', cursor: 'pointer' }}>Logout</li>
            <li onClick={handleDeleteAccount} style={{ color: 'red', cursor: 'pointer' }}>Delete Account</li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ProfilePage;
