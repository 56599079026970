import React from 'react';
import './css/friendrequestsuccess.style.css';

const FriendRequestSuccess = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="friendrequestsuccess-overlay">
      <div className="friendrequestsuccess-content">
        <h2 className="friendrequestsuccess-title">Friend Request Sent Successfully</h2>
        <p className="friendrequestsuccess-description">
          Your friend request has been sent. The user will be notified.
        </p>
        <div className="friendrequestsuccess-actions">
          <button className="friendrequestsuccess-btn-close" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FriendRequestSuccess;
