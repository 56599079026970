import React from 'react';
import { Link } from 'react-router-dom';  
import './css/footer.style.css'; 

function Footer() {
    return (
        <footer className="footer-container">
            <div className="footer-icons">
                <Link to="/dashboard" className="footer-icon">
                    <i className="fas fa-home"></i> {/* Home icon */}
                </Link>
                <Link to="/search" className="footer-icon">
                    <i className="fas fa-search"></i> {/* Search icon */}
                </Link>
                <Link to="/add" className="footer-icon">
                    <i className="fas fa-plus-circle"></i> {/* Add icon */}
                </Link>
                <Link to="/notifications" className="footer-icon">
                    <i className="fas fa-bell"></i> {/* Notification icon */}
                </Link>
                <Link to="/profile" className="footer-icon">
                    <i className="fas fa-user"></i> {/* Profile icon */}
                </Link>
            </div>
        </footer>
    );
}

export default Footer;
