// Import necessary functions from Firebase SDK
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc, addDoc, Timestamp, collection } from "firebase/firestore"; // Firestore
import { getStorage } from "firebase/storage"; // Firebase Storage

// Firebase config for Firestore (your original config)
const firebaseConfig1 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_1,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_1,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_1,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_1,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_1,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_1,
};

// Firebase config for Firebase Storage (for photos)
const firebaseConfig2 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_2,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_2,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_2,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_2,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_2,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_2,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_2,
};


// Initialize Firebase apps
const app1 = initializeApp(firebaseConfig1);  // For Firestore
const app2 = initializeApp(firebaseConfig2, "app2");  // For Firebase Storage

// Initialize Firestore and Storage
const db = getFirestore(app1); // Use Firestore from app1
const storage = getStorage(app2); // Use Storage from app2

export { db, storage, doc, setDoc, getDoc, Timestamp, addDoc, collection };
