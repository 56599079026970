/* global Pi */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/home.style.css';
import { db, getDoc, doc, setDoc, Timestamp } from '../firebase';
import axios from 'axios';

// Import images
import slide1 from './assets/slide1.jpeg';
import slide2 from './assets/slide2.jpeg';
import slide3 from './assets/slide3.jpeg';

function HomePage() {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();

  // Check if the user is already logged in when the component mounts
  useEffect(() => {
    const storedUid = localStorage.getItem('uid');
    const storedUser = JSON.parse(localStorage.getItem('user'));

    if (storedUid && storedUser) {
      axios.defaults.headers.common['Authorization'] = 'test-token';
      console.log('User already logged in:', storedUser);
      navigate('/dashboard');
    }
  }, [navigate]);

  // Simulate login for test accounts
  const simulateLogin = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const docSnapshot = await getDoc(userRef);

      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        localStorage.setItem('uid', userId);
        localStorage.setItem('user', JSON.stringify(userData));
        axios.defaults.headers.common['Authorization'] = 'test-token';
        console.log(`Simulated login for ${userId}:`, userData);
        navigate('/dashboard');
      } else {
        console.error(`User document for ${userId} not found.`);
        setError(`User ${userId} not found.`);
      }
    } catch (error) {
      console.error(`Error during simulated login for ${userId}:`, error);
      setError(`Failed to simulate login for ${userId}`);
    }
  };

  // Handle Pi authentication
  const handleAuthenticate = () => {
    Pi.authenticate(['username'])
      .then((authResult) => {
        const accessToken = authResult.accessToken;
        const user = authResult.user;
        setAccessToken(accessToken);
        setUser(user);

        const userRef = doc(db, 'users', user.uid);
        getDoc(userRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              console.log('User already exists in Firestore:', user);
              localStorage.setItem('uid', user.uid);
              localStorage.setItem('user', JSON.stringify(user));
              axios.defaults.headers.common['Authorization'] = accessToken;
              navigate('/dashboard');
            } else {
              console.log('User not found, showing modal...');
              setIsModalOpen(true);
            }
          })
          .catch((error) => {
            console.error('Error checking user in Firestore:', error);
            setError('Error checking user data');
          });
      })
      .catch((error) => {
        console.error('Authentication failed:', error);
        setError('Authentication failed');
      });
  };

  // Handle acceptance of terms in the modal
  const handleAcceptTerms = () => {
    const userRef = doc(db, 'users', user.uid);
    setDoc(userRef, {
      uid: user.uid,
      username: user.username,
      Timestamp: Timestamp.now(),
      privacypolicyaccepted: true,
      termsandconditionsaccepted: true
    })
      .then(() => {
        console.log('User added to Firestore successfully with terms accepted');
        localStorage.setItem('uid', user.uid);
        localStorage.setItem('user', JSON.stringify({ ...user, privacypolicyaccepted: true, termsandconditionsaccepted: true }));
        axios.defaults.headers.common['Authorization'] = accessToken;
        setIsModalOpen(false);
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Error adding user data to Firestore:', error);
        setError('Error adding user data');
      });
  };

  // Handle rejection of terms
  const handleCancelTerms = () => {
    console.log('User declined terms');
    setIsModalOpen(false);
  };

  // Modal for terms and conditions
  const TermsModal = ({ isOpen, onClose, onAccept }) => {
    if (!isOpen) return null;

    return (
<div className="privacyterm-modal-overlay">
  <div className="privacyterm-modal">
    <h2>Privacy Policy & Terms and Conditions</h2>
    <p>
      Please read and accept our 
      <a href="/privacypolicy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a> and 
      <a href="/termsandconditions" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a> to proceed.
    </p>
    <div className="privacyterm-modal-buttons">
      <button onClick={onAccept}>Accept</button>
      <button onClick={onClose}>Cancel</button>
    </div>
  </div>
</div>

    );
  };

  // Render slides based on the currentSlide state
  const renderSlider = () => {
    const backgroundImages = [slide1, slide2, slide3];
    switch (currentSlide) {
      case 1:
        return (
          <div
            className="slider"
            style={{ backgroundImage: `url(${backgroundImages[0]})` }}
          >
            <div className="slider-content">
              <h2>Welcome to Ring Match</h2>
              <p>Find your perfect match today with us.</p>
            </div>
          </div>
        );
      case 2:
        return (
          <div
            className="slider"
            style={{ backgroundImage: `url(${backgroundImages[1]})` }}
          >
            <div className="slider-content">
              <h2>Secure and Trusted</h2>
              <p>Our platform ensures your privacy and security while finding your soulmate.</p>
            </div>
          </div>
        );
      case 3:
        return (
          <div
            className="slider"
            style={{ backgroundImage: `url(${backgroundImages[2]})` }}
          >
            <div className="slider-content">
              <h2>Join us today and find your match</h2>
              <p>Sign up now and start your journey towards love and connection.</p>
            </div>
          </div>
        );
      case 4:
        return renderLoginButtons();
      default:
        return null;
    }
  };

  // Render login buttons
  const renderLoginButtons = () => (
    <div className="login">
      <button type="button" onClick={handleAuthenticate}>Login</button>
     {/*} <h3>Test Accounts</h3>
      <button type="button" onClick={() => simulateLogin('user1')}>Login as User1</button>
      <button type="button" onClick={() => simulateLogin('user2')}>Login as User2</button>
      <button type="button" onClick={() => simulateLogin('user3')}>Login as User3</button>
      <button type="button" onClick={() => simulateLogin('user4')}>Login as User4</button> */}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );

  // Handle navigation between slides
  const handleNext = () => {
    if (currentSlide < 3) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(4);
    }
  };

  // Get button text based on the current slide
  const getButtonText = () => (currentSlide === 3 ? 'Continue' : 'Next');

  return (
    <div className="homepage">
      {renderSlider()}
      {currentSlide < 4 && (
        <button className="next-btn" onClick={handleNext}>
          {getButtonText()}
        </button>
      )}
      <TermsModal
        isOpen={isModalOpen}
        onClose={handleCancelTerms}
        onAccept={handleAcceptTerms}
      />
    </div>
  );
}

export default HomePage;