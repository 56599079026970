import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage'; 
import Dashboard from './components/Dashboard';

// Pages
import Profile from './components/pages/ProfilePage';
import AddPage from './components/pages/AddPage';
import MessagePage from './components/pages/MessagePage';
import NotificationPage from './components/pages/NotificationPage';
import SearchPage from './components/pages/SearchPage';

// Options
import YourProfile from './components/pages/options/YourProfile';
import EditProfile from './components/pages/options/EditProfile';
import Friends from './components/pages/options/Friends';
import PrivacyPolicy from './components/pages/options/PrivacyPolicy';
import TermsAndConditions from './components/pages/options/TermsCondition';
import ContactUs from './components/pages/options/ContactUs';



function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/add" element={<AddPage />} />
                <Route path="/message" element={<MessagePage />} />
                <Route path="/notifications" element={<NotificationPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/yourprofile" element={<YourProfile />} />
                <Route path="/editprofile" element={<EditProfile />} />
                <Route path="/friends" element={<Friends />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/termsandconditions" element={<TermsAndConditions />} />
                <Route path="/contactus" element={<ContactUs />} />
            </Routes>
        </Router>
    );
}

export default App;
